<template>
  <div>
    <header>
      <HeaderNavHomepage />
      <section class="hero">
        <img src="/img/blur-circle.svg" class="blur1" alt="" />
        <img src="/img/blur-circle.svg" class="blur2" alt="" />
        <img src="/img/blur-circle.svg" class="blur3" alt="" />

        <div class="content">
          <h1>Articles</h1>
        </div>
      </section>
    </header>

    <main class="posts-grid">
      <div v-for="post in posts" :key="post.slug" class="posts-grid-card">
        <PostCard
          :title="post.title"
          :slug="post.slug"
          :image-src="post.imageSrc"
        />
      </div>
    </main>

    <MainCCFooter />
  </div>
</template>

<script setup>
import HeaderNavHomepage from '@/components/HeaderNavHomepage.vue';
import MainCCFooter from '@/components/MainCCFooter.vue';
import { buildPageMeta } from '@/utils/meta';
import { useHead } from '@vueuse/head';
import PostCard from './PostCard.vue';

useHead(
  buildPageMeta({
    title: 'Articles | Ecommerce Technology for Creators & Communities',
    description: null,
    url: 'https://www.helixpay.ph/merch',
    image: 'https://www.helixpay.ph/img/hxp-merch-meta.png',
  })
);

const posts = [
  {
    title:
      'HelixPay Enhances Events through On-Demand ID Badges: A Game-Changer for Organizers',
    slug: 'helix-pay-enhances-events-through-on-demand-id-badges-a-game-changer-for-organizers',
    imageSrc: '/img/articles/id-printing-1.jpg',
    createdAt: '',
  },
  {
    title:
      'HelixPay Revolutionizes Online Booking for Visayas’ Largest Water Parks: Waterworld Cebu and Waterworld Iloilo',
    slug: 'revolutionizes-online-booking-for-visayas-largest-water-parks',
    imageSrc: '/img/articles/waterworld.jpg',
    createdAt: '2024-09-05 21:32:43',
  },
];
</script>

<style scoped>
.hero {
  background: linear-gradient(-45deg, #0c3c52, black);
  color: white;
  padding: 7rem 1rem;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.hero .content {
  position: relative;
  z-index: 9;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 0 5px;
}

.hero h1 {
  margin: 0;
  font-size: 2rem;
  letter-spacing: 0.05rem;
  font-weight: 700;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ MAIN CIRCLE */

.blur1 {
  width: 45%;
  right: -15%;
  bottom: -30%;
  z-index: 8;
  position: absolute;
  pointer-events: none;
  animation: move 200s ease-out infinite;
}

@keyframes move {
  0% {
    width: 45%;
    right: -20%;
  }
  100% {
    width: 50%;
    right: 100%;
    bottom: 40%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 2 */

.blur2 {
  opacity: 0.5;
  width: 15%;
  left: 1%;
  bottom: -30%;
  z-index: 7;
  position: absolute;
  pointer-events: none;
  animation: right 140s ease-out infinite;
}

@keyframes right {
  0% {
    width: 15%;
    left: -15%;
    bottom: -10%;
  }
  100% {
    width: 25%;
    left: 100%;
    bottom: 20%;
  }
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Blur 3 */

.blur3 {
  opacity: 0.5;
  width: 5%;
  left: 1%;
  top: -10%;
  z-index: 6;
  position: absolute;
  pointer-events: none;
  animation: lower 150s ease-out infinite;
}

@keyframes lower {
  0% {
    width: 5%;
    left: -15%;
    top: -10%;
  }
  100% {
    width: 10%;
    left: 100%;
    top: 50%;
  }
}

.posts-grid {
  padding: 2rem 1rem 4rem;
}

.posts-grid .posts-grid-card {
  width: 100%;
  padding-bottom: 2rem;
}

@media (min-width: 600px) {
  .posts-grid {
    display: flex;
    padding: 3rem 1.5rem 4rem;
    margin: 0 -1rem;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .posts-grid .posts-grid-card {
    width: 50%;
    padding: 0 1rem 2rem;
  }
}
/*
@media (min-width: 768px) {
  .posts-grid .posts-grid-card {
    width: 33.33%;
  }
} */

@media (min-width: 768px) {
  .posts-grid {
    max-width: 1100px;
    margin: 0 auto;
  }

  .posts-grid .posts-grid-card {
    width: 33.33%;
  }
}
</style>
